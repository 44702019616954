<template>
  <div class="user_content">
    <div class="details_top">
      <div>{{ list.orderTypeValue }}</div>
      <div style="font-size:12px;">
        <div v-show="list.orderType !== 3">
          <div>{{ list.orderTypeDesc }}</div>
          <div v-if="list.countDowm !== 0">请在{{ strTimer }}内支付，超时订单将自动取消</div>
          <div>{{ list.remindMsg }}</div>
        </div>
      </div>
    </div>
    <div v-if="list.express && list.express.context" class="details_name">
      <div style="color:#8080ff;">{{ list.express.context }}</div>
      <div style="color:#999;">{{ list.express.time }}</div>
    </div>
    <div class="details_name">
      <!--          <div>-->
      <!--            <span>{{ list.linkName }}</span>-->
      <!--            <span>{{ list.linkTel }}</span>-->
      <!--          </div>-->
      <!--          <div>{{ list.address }}</div>-->
      <div v-if="add">
        <span> {{ add.name }}</span>
        <span>{{ add.mobile }}</span>
      </div>
      <div v-if="add">{{ add.address }}</div>
      <div v-if="list && !add">
        <span> {{ list.linkName }}</span>
        <span>{{ list.linkTel }}</span>
      </div>
      <div v-if="list && !add">{{ list.address }}</div>
      <div class="add" @click="address()">修改地址</div>
    </div>
    <div v-if="list.goodsInfo[0].payStatus !== 0" class="shares_box">
      <h4>股票账户信息</h4>
      <div class="item">
        <div class="left">
          <div>姓名：{{ nameText }}</div>
          <div>电话：{{ phoneText }}</div>
          <div>邮箱：{{ sharesText }}</div>
          <div>存股人身份证号：{{ cardIDText }}</div>
        </div>
        <div v-if="gPaccount === '0'" class="right">
          <span @click="isShares = true">去填写</span>
        </div>
      </div>
    </div>
    <van-popup v-model="isShares" class="pop_box">
      <div>绑定存股邮箱</div>
      <van-field v-model="form.nameValue" name="姓名" label="姓名" placeholder="请输入姓名" @input="onName" />
      <van-field v-model="form.phoneValue" name="电话" label="电话" placeholder="请输入电话" @input="onPhone" />
      <van-field v-model="form.sharesValue" name="存股邮箱" label="存股邮箱" placeholder="请输入存股邮箱" @input="onShares" />
      <van-field v-model="form.cardIDValue" name="身份证号" label="身份证号" placeholder="请输入存股身份证号" @input="onCardID" />

      <div class="btn" @click="onSubmit">确定</div>
    </van-popup>
    <div class="commodity">
      <div class="title">订单信息</div>

      <div v-for="(item, index) in list.goodsInfo" :key="index">

        <div class="comm" @click="details(item)">
          <div class="comm_img"><img :src="item.goodsImg" alt=""></div>
          <div class="comm_test">
            <div>{{ item.goodsName }}</div>
            <div>
              <span>数量：{{ item.goodsNum }}</span>
              <span>规格：{{ item.goodsSpec }}</span>
            </div>
            <div>
              <span>¥{{ item.goodsPrice }}</span>
              <span>*{{ item.goodsNum }}</span>
            </div>
          </div>
        </div>
        <div class="comm_btn">
          <b v-if="item.refundButtonType === 3">{{ item.refundButtonText }}</b>
          <!-- <span v-if="item.isMakesure === 1" @click="confirm(item)">确认收货</span> -->
          <!-- <span v-if="item.isCanComment === 1" @click="evaluate(item)">去评价</span> -->
          <!-- <span v-if="item.isCan === 1" @click="logistics(item)">查看物流</span> -->
        </div>
      </div>
      <div class="comm_z">
        <div><span>商品总计</span><span>¥{{ list.totalMoney }}</span></div>
        <div><span>优惠劵</span><span>¥{{ list.couponPrice }}</span></div>
        <div><span>总运费</span><span>¥{{ list.totalFeight }}</span></div>
        <div><span>悦豆抵扣</span><span>¥{{ list.coin }}</span></div>
        <div><span style="color:#000;">应付款（不含运费）</span><span style="color:#F7263c;">¥{{ list.totalMoney
        }}</span></div>
      </div>
    </div>
    <div class="details_orderNo">
      <div class="orderNo">
        <span>订单编号: <i id="ordernumber">{{ list.orderNo }}</i> </span><span class="copy" @click="copy">复制</span>
      </div>
      <div>下单时间: {{ list.createTime }}</div>
      <div v-show="list.orderType !== 0 && list.orderType !== 3">支付方式: {{ list.payType }}</div>
    </div>

    <div class="orderfoot">
      <!-- <span
        v-if="list.goodsInfo && list.goodsInfo[0].isCan === 0 && list.orderType !== 0"
        @click="Delete(list)"
      >删除订单</span> -->
      <!-- <span v-show="list.orderType !== 3" @click="cancelOrder(list)">取消订单</span> -->
      <span v-if="list.countDowm !== 0" class="zf" @click="payment(list)">去支付 {{ strTimer }}</span>
    </div>
    <van-dialog
      v-model="show"
      message="如已经支付，请不要重新支付！"
      confirm-button-text="继续支付"
      show-cancel-button
      @confirm="pay(list)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast, Popup, Field } from 'vant'

Vue.use(Toast).use(Dialog).use(Popup).use(Field)
import {
  flashOrderDetailH5,
  mallNewOrderDelete,
  confirmReceipt,
  getPayVersion,
  addAccount,
  selectAccount,
  // newMallPayV4,
  cancelOrders
} from '@/services/userApi'
import { weChatPay } from '@/utils/payment'
import { copyText } from '@/utils/common/utils'

export default {
  data() {
    return {
      add: JSON.parse(window.localStorage.getItem('addressItem')),
      show: false,
      orderNo: '',
      uid: '',
      list: '',
      secunds: '',
      strTimer: '',
      openId: '',
      fromName: '', // 上一个路由地址
      form: {
        sharesValue: '',
        phoneValue: '',
        nameValue: '',
        cardIDValue: ''
      },
      sharesText: '',
      nameText: '',
      phoneText: '',
      cardIDText: '',
      gPaccount: '',
      mid: '',
      isShares: false
    }
  },
  // beforeRouteEnter: (to, from, next) => {
  //   next((vm) => {
  //     vm.fromName = from.name
  //   })
  // },
  created() {
    this.uid = localStorage.getItem('uid')
    this.openId = localStorage.getItem('openid')
    this.orderNo = this.$route.query.orderNo
    this.gPaccount = this.$route.query.gPaccount
    this.mid = this.$route.query.mid
    this.getinfo()
  },
  methods: {
    address() {
      this.$router.push({ path: '/addressList', query: { id: this.$store.state.addressInfo.id }})
    },
    pop_value() {

    },
    // 支付倒计时
    ComputetTime(totalSecond) {
      const that = this
      let sec = parseInt(totalSecond)
      let clockCount = {}
      let strTimer = ''
      clockCount = setInterval(() => {
        if (sec === 0) {
          clearInterval(clockCount)
          return false
        }
        strTimer = that.secundTodata(sec)
        that.strTimer = strTimer
        sec--
      }, 1000)
    },
    secundTodata(result) {
      var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
      var m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
      var s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))
      if (h === 0) {
        result = m + ':' + s
      } else {
        result = h + ':' + m + ':' + s
      }
      return result
    },
    getinfo() {
      const prom = {
        orderNo: this.orderNo,
        mid: this.mid
      }
      flashOrderDetailH5(prom).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data
          this.secunds = res.data.countDowm
          this.ComputetTime(res.data.countDowm)
          this.getselectAccount()
        }
      })
    },
    copy() {
      copyText(this.list.orderNo, () => {
        Toast('复制成功')
      })
    },
    details(o) {
      const obj = {
        goodId: o.goodsId,
        skuId: o.skuId,
        product_type: 1
      }
      this.$store.dispatch('godetail', obj)
    },
    // 查看物流
    logistics(v) {
      this.$router.push({
        path: '/logistics',
        query: {
          recordId: v.recordId,
          goodName: v.goodsName,
          goodSpec: v.goodsSpec,
          payPrice: v.goodsPrice,
          goodCover: v.goodsImg,
          goodNum: v.goodsNum
        }
      })
    },
    // 删除订单
    Delete(n) {
      Dialog.confirm({
        message: '确认删除此订单？'
      }).then(() => {
        const prom = {
          orderNo: n.orderNo
        }
        mallNewOrderDelete(prom).then(res => {
          if (Number(res.code) === 200) {
            Toast(res.msg)
            window.history.go(-1)
          } else {
            Toast(res.msg)
          }
        })
      })
    },
    // 取消订单
    cancelOrder(item) {
      Dialog.confirm({
        message: '确认取消此订单？'
      }).then(() => {
        const prom = {
          orderNo: item.orderNo
        }
        cancelOrders(prom).then(res => {
          this.getinfo()
        })
      })
    },

    // 支付
    payment() {
      this.show = true
    },
    // 发起支付
    pay(n) {
      this.show = false
      // const _self = this
      getPayVersion({ type: 1 }).then(res => {
        if (Number(res.code) === 200) {
          // if (this.fromName === 'pay-consequent') {
          //   this.$router.push({
          //     path: '/pay-consequent',
          //     query: {
          //       orderNo: n.orderNo,
          //       goodsId: this.list.goodsInfo[0].goodsId
          //     }
          //   })
          // } else {
          this.$router.push({
            path: '/pay',
            query: {
              orderNo: n.orderNo,
              goodsId: this.list.goodsInfo[0].goodsId
            }
          })
          // }
          // return

          // const version = res.data && res.data.status
          // const patmr = {
          //   ordersn: n.orderNo,
          //   openId: _self.openId,
          //   type: 147,
          //   payVersion: version,
          //   returnUrl: 'https://web.yuetao.vip/odersky?odersn=' + this.orderNo
          // }
          // newMallPayV4(patmr).then(res => {
          //   if (Number(res.code) === 200) {
          //     _self.ordersn = res.ordersn
          //     const params = res.pay.getwayBody
          //     _self.wxPay(params)
          //   } else {
          //     Toast(res.msg)
          //   }
          // })
        }
      })
    },
    /**
                 * 调用微信支付
                 * 2021-06-04 10:22:15
                 * @author SnowRock
                 * @param params
                 */
    wxPay(params) {
      var that = this
      weChatPay(params).then(res => {
        // 支付成功后的操作
        Toast('支付成功')
        that.isDisabledSubmitBtn = true
        that.$router.push({
          path: '/oderSky',
          query: { ordersn: that.ordersn }
        })
      }).catch(e => {
        if (e) {
          const { msg = null } = e
          if (msg === 'isNotWeChat') {
            Toast('请在微信浏览器下进行支付')
          }
          if (msg === 'cancel') {
            // 取消支付的操作
            Toast('取消支付')
            that.paymentResult(that.ordersn, 'UNKNOWN', that.orderNo)
          }
          if (msg === 'fail') {
            // 支付失败的操作
            Toast('支付失败')
            that.paymentResult(that.ordersn, 'UNKNOWN', that.orderNo)
          }
        }
        that.isDisabledSubmitBtn = false
      })
    },
    getselectAccount() {
      selectAccount({
        orderSon: this.orderNo
      }).then(res => {
        this.sharesText = res.data.account
        this.phoneText = res.data.mobile
        this.nameText = res.data.name
        this.cardIDText = res.data.id_card
      })
    },
    onName(e) {
      this.form.nameValue = e
    },
    onPhone(e) {
      this.form.phoneValue = e
    },
    onShares(e) {
      this.form.sharesValue = e
    },
    onCardID(e) {
      this.form.cardIDValue = e
    },
    onSubmit() {
      const { form } = this
      if (!form.nameValue) {
        return Toast('请输入姓名')
      }
      if (!form.phoneValue) {
        return Toast('请输入电话')
      }
      if (!form.sharesValue) {
        return Toast('请输入股票账户')
      }
      if (!form.cardIDValue) {
        return Toast('请输入存股人身份证号')
      }
      addAccount({
        orderSon: this.orderNo,
        name: this.form.nameValue,
        mobile: this.form.phoneValue,
        account: this.form.sharesValue,
        cardID: this.form.cardIDValue
      }).then(res => {
        console.log(res)
        Toast('添加成功')
        this.isShares = false
        this.getinfo()
        this.gPaccount = 1
      })
    },
    paymentResult(ordersn, status, orderNo) {
      this.$router.push({
        path: '/oderSky',
        query: {
          ordersn: ordersn,
          payType: 2,
          payFormNo: orderNo,
          status: status
        }
      })
    },
    // 去评价
    evaluate(n) {
      this.$router.push({
        path: '/evaluate',
        query: {
          info: n
        }
      })
    },
    confirm(n) {
      const prom = {
        subOrderSn: n.subOrderSn
      }
      confirmReceipt(prom).then(res => {
        if (Number(res.code) === 200) {
          Toast(res.msg)
        } else {
          Toast(res.msg)
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.user_content {
    width: 100%;
    height: auto;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f6f6f6;
    padding-bottom: 80px;

    .orderfoot {
        position: fixed;
        left: 0;
        bottom: 0;
        background: #fff;
        padding: 13px;
        border-top: 1px solid #eee;
        width: 100%;
        text-align: right;

        &>span {
            margin-left: 10px;
            padding: 5px;
            border: 1px solid #999;
            border-radius: 10px;
            font-size: 10px;
        }

        .zf {
            border: 1px solid #F7263C;
            color: #fff;
            background: #F7263C;
        }
    }

    .details_top {
        padding: 20px;
        background: #F7263C;
        color: #fff;
        text-align: left;
        font-weight: 500;
        line-height: 25px;

        &>div:nth-child(2) {
            line-height: 15px;
            font-weight: 400;
            margin-top: 10px;
        }
    }

    .details_name {
        position: relative;
        background: #FFFFFF;
        padding: 20px 30px;
        margin-bottom: 10px;

        &>div {
            text-align: left;
        }

        &>div:nth-child(1) {
            font-weight: 500;

            &>span:nth-child(1) {
                margin-right: 40px;
            }
        }

        &>div:nth-child(2) {
            margin-top: 10px;
            font-size: 12px;
            width: 70%;
            line-height: 15px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .add {
            position: absolute;
            right: 12px;
            top: 30px;
            font-size: 10px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            padding: 5px 12px;
            box-sizing: border-box;
            border-radius: 18px;
            color: rgba(0, 0, 0, 0.6);
        }
    }

    .shares_box {
        position: relative;
        background: #FFFFFF;
        padding: 20px 30px;
        margin-bottom: 10px;
        text-align: left;

        h4 {
            font-size: 14px;
            font-weight: bold;
        }

        .item {
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                color: #333;

                div {
                    padding-top: 5px
                }
            }

            .right {
                border: 1px solid #999;
                border-radius: 100px;
                padding: 3px 10px;

            }
        }
    }

    .pop_box {
        padding: 20px 0;
        font-size: 16px;
        width: 80%;
        text-align: center;

        >div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .van-cell-group {
            margin-top: 10px;
        }

        .btn {
            background: #FF3030;
            color: #fff;
            border-radius: 5px;
            width: 120px;
            line-height: 30px;
            margin: 20px auto 0;
        }
    }

    .commodity {
        background: #fff;
        padding: 13px;

        .title {
            text-align: left;
            font-weight: 500;
        }

        .comm {
            display: flex;
            margin-top: 10px;

            .comm_img {
                margin-right: 10px;

                img {
                    border-radius: 10px;
                    max-width: 100px;
                    max-height: 100px;
                }
            }

            .comm_test {
                text-align: left;
                font-size: 12px;

                &>div:nth-child(1) {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    line-height: 17px
                }

                &>div:nth-child(2) {
                    display: flex;
                    margin-top: 6px;
                    color: #999;
                    font-size: 12px;

                    &>span:nth-child(1) {
                        flex: 3;
                    }

                    &>span:nth-child(2) {
                        flex: 9;
                    }
                }

                &>div:nth-child(3) {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;

                    &>span:nth-child(1) {
                        font-weight: 500;
                    }
                }
            }
        }

        .comm_btn {
            padding: 13px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &>span {
                margin-left: 10px;
                padding: 5px;
                border: 1px solid #999;
                border-radius: 10px;
                font-size: 10px;
            }

            &>b {
                color: rgb(248, 142, 3);
                font-weight: 400;
                font-size: 12px;
            }
        }

        .comm_z {
            margin-top: 10px;

            &>div {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                color: #999;
                font-size: 12px;
            }
        }
    }

    .details_orderNo {
        background: #fff;
        padding: 13px;
        margin-top: 10px;
        text-align: left;
        line-height: 20px;
        color: #999;
        font-size: 12px;

        .orderNo {
            display: flex;
            justify-content: space-between;

            .copy {
                margin-left: 10px;
                border: 1px solid #999;
                padding: 2px 10px;
                border-radius: 4px;
            }
        }
    }
}
</style>
